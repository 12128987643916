<template>
  <div id="map" style="height: 500px; width: 100%;"></div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import api from '@/services/api';

export default {
  name: 'MapView',
  data() {
    return {
      map: null,
      marker: null,
      intervalId: null,
      driverId: 1, // Remplacez par la méthode appropriée pour obtenir l'ID du conducteur
      missionId: 1  // Remplacez par la méthode appropriée pour obtenir l'ID de la mission
    };
  },
  mounted() {
    this.initializeMap();
    this.updatePosition();
    this.intervalId = setInterval(this.updatePosition, 10000); // Mise à jour toutes les 10 secondes
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    initializeMap() {
      this.map = L.map('map').setView([51.505, -0.09], 13);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      this.marker = L.marker([51.505, -0.09]).addTo(this.map);
    },
    async updatePosition() {
      try {
        const response = await api.map(this.driverId, this.missionId);
        const location = response.data;
        if (location.latitude && location.longitude) {
          const newLatLng = new L.LatLng(location.latitude, location.longitude);
          this.marker.setLatLng(newLatLng);
          this.map.setView(newLatLng, 13);
        } else {
          console.error('Invalid location data:', location);
        }
      } catch (error) {
        console.error('Failed to fetch latest location:', error);
      }
    }
  }
};
</script>

<style>
#map {
  height: 100%;
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import DriverDashboardView from "../views/DriverDashboardView.vue";
import NonAccessView from "../views/NonAccessView.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import VerifyEmailView from '../views/VerifyEmailView.vue';
import ForgotPasswordView from '../views/ForgotPasswordView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';

//import Dashboard from '@/components/Dashboard'

import ConducteursView from '../views/ConducteursView.vue';
import VehiculesView from '../views/VehiculesView.vue';
import MissionReportsView from '../views/MissionReportsView.vue';
import PositionsView from '../views/PositionsView.vue';
import MissionsView from '../views/MissionsView.vue';
import Statistics from '../views/Statistics.vue';
import Participants from '../views/ParticipantsView.vue';
import DemandeOmForm from '../views/DemandeOmForm.vue';

import DemandeOmListe from '../views/DemandeOmListe.vue';
import EditDemandeOmModal from '../views/EditDemandeOmModal.vue';
import DemandeOmDetail from '../views/DemandeOmDetail.vue';

import GenerateOm from '../views/GenerateOm.vue';
import OmsList from '../views/OmList.vue';


import store from '../store';
import TrackerView from '@/views/TrackerView.vue';
import MapView from '@/views/MapView.vue';

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard-driver",
    name: "Driver_Dashboard",
    component: DriverDashboardView,
  },
  {
    path: "/non-access",
    name: "NonAcess",
    component: NonAccessView,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },

 
  {
    path: '/map',
    name: 'Map',
    component: MapView
  },
  {
    path: '/tracker',
    name: 'Tracker',
    component: TrackerView
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmailView
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordView
  },
  {
    path: '/conducteurs',
    name: 'Conducteurs',
    component: ConducteursView,
  },
  {
    path: '/vehicules',
    name: 'Vehicules',
    component: VehiculesView,
  },
  {
    path: '/missions',
    name: 'Missions',
    component: MissionsView,
  },
 /* {
    path: '/missionreports',
    name: 'Missionreports',
    component: MissionReportsView,
  },*/
  {
    path: '/missions/:missionId/rapports',
    name: 'Missionreports',
    component: MissionReportsView,
    props: true
  },
  {
    path: '/missions/:missionId/positions',
    name: 'Positions',
    component: PositionsView,
    props: true
  },
  {
    path: '/tables',
    name: 'Tables',
    component: Tables,
    
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    
  },
  {
    path: '/demandomform',
    name: 'DemandeOmForm',
    component: DemandeOmForm,
    
  },
  {
    path: '/participants',
    name: 'Participants',
    component: Participants,
    
  },
  {
    path: '/demandomliste',
    name: 'ListeDemandeOms',
    component: DemandeOmListe,
    
  },
  {
    path: '/editdemandom/:id',
    name: 'EditDemandeOm',
    component: EditDemandeOmModal,
    
  },
  {
    path: '/demandomdetails/:id',
    name: 'DemandeOmDetail',
    component: DemandeOmDetail,
    
  },
  {
    path: '/generateom/:demandeOmId',
    name: 'GenerateOm',
    component: GenerateOm,
    
  },
  {
    path: '/oms/demandeOm/:demandeOmId',
    name: 'ListeOms',
    component: OmsList,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
// RegExp pour les chemins dynamiques
// RegExp pour les chemins dynamiques
//const missionReportsRegex = /^\/missions\/\d+\/rapports$/;
//const missionPositionsRegex = /^\/missions\/\d+\/positions$/;

/*router.beforeEach((to, from, next) => {
  const allowedPaths = [
    '/login', 
    '/mission_orders', 
    '/register', 
    '/tables', 
    '/tracker', 
    '/map', 
    '/conducteurs', 
    '/vehicules', 
    '/missions', 
    '/admin-dashboard', 
    '/dashboard-default', 
    '/statistics'
   // '/mission_orders/1720173570789-Ebauche%20newsletter%20DEDRAS.pdf'
    
  ];

  const isAllowedPath = allowedPaths.includes(to.path) || 
                        missionReportsRegex.test(to.path) || 
                        missionPositionsRegex.test(to.path);*/
                        const allowedPaths = [
                          '/login',
                          '/register',
                          '/verify-email',
                          '/forgot-password',
                          '/reset-password',
                          '/demandomform',
                          '/participants',
                          '/demandomliste'

                          // Ajoutez ici les autres chemins autorisés pour les utilisateurs non authentifiés
                        ];
                        
                        router.beforeEach((to, from, next) => {
                          const isAuthenticated = store.getters.isAuthenticated;
                          const isAllowedPath = allowedPaths.includes(to.path);
                        
                          if (!isAuthenticated && !isAllowedPath) {
                            next('/login');
                          } else {
                            next();
                          }
                        });
export default router;

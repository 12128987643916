<template>
  <div v-if="isAuthenticated">
    <h1 class="page-title" style="color: bisque;">Liste des demandes d'Ordre de Mission</h1>
    
    <!-- Search Input -->
    <div class="search-container">
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="Rechercher..." 
        class="form-control mb-3"
      />
    </div>

    <div class="table-responsive p-0">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-10" @click="sortBy('utilisateur.nom')">Demandeur</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('lieu_a_se_rendre')">Lieu à se rendre</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('lieu_de_provenance')">Lieu de provenance</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('objet')">Objet</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('date_de_depart')">Date de départ</th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-9" @click="sortBy('date_de_retour')">Date de retour</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="demande in filteredAndSortedDemandeOms" :key="demande.id">
            <td><p class="text-xs text-secondary mb-0">{{ demande.utilisateur.nom}}</p></td>
            <td class="text-xs font-weight-bold mb-0 text-center">{{ demande.lieu_a_se_rendre }}</td>
            <td class="text-xs font-weight-bold mb-0 text-center">{{ demande.lieu_de_provenance }}</td>
            <td class="text-xs font-weight-bold mb-0 text-center">{{ truncateText(demande.objet,50) }}</td>
            <td class="align-middle text-center"><span class="text-secondary text-xs font-weight-bold">{{ formatDate(demande.date_de_depart) }}</span></td>
            <td class="align-middle text-center"><span class="text-secondary text-xs font-weight-bold">{{ formatDate(demande.date_de_retour) }}</span></td>
            <td>
               <div class="d-flex flex-column justify-content-center mr-3">
                <button @click="viewDemandeOm(demande.id)" class="btn btn-primary"><i class="fas fa-eye"></i></button>
                <p class="text-xs text-secondary mb-0">Visual.</p>
               </div>
            </td>
            <td>
           <div class="d-flex flex-column justify-content-center mr-3">
              <button @click="openEditPage(demande.id)" class="btn btn-secondary"><i class="fas fa-edit"></i></button>
              <p class="text-xs text-secondary mb-0">Modif.</p>
           </div>
           </td>
           <td>
           <div class="d-flex flex-column justify-content-center mr-3">
              <button v-if="isDemandeNotValidate(demande)" @click="validateDemandeOm(demande.id)" class="btn btn-info"><i class="fas fa-check"></i></button>
              <button v-if="isDemandeValidate(demande)" @click="validateDemandeOm(demande.id)" class="btn btn-success"><i class="fas fa-check"></i></button>
              <p class="text-xs text-secondary mb-0">Valid.</p>
           </div>
           </td>
           <td>
           <div class="d-flex flex-column justify-content-center mr-3">
              <button  v-if="shouldShowGenerateButton(demande)" @click="generateOm(demande.id)" class="btn btn-warning"><i class="fas fa-circle"></i></button>
              <p class="text-xs text-secondary mb-0">Génér.</p>
           </div>
           </td>
           
            
            <!--<td>
              <button @click="viewDemandeOm(demande.id)" class="btn btn-info">Visualiser</button>
              <button @click="openEditPage(demande.id)" class="btn btn-warning">Modifier</button>
              <button @click="validateDemandeOm(demande.id)" class="btn btn-success">Valider</button>
              <button 
                v-if="shouldShowGenerateButton(demande)" 
                @click="generateOm(demande.id)" 
                class="btn btn-primary">Générer</button>
            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import api from '@/services/api';

export default {
  name: 'ListeDemandeOms',
  data() {
    return {
      demandeOms: [],
      searchQuery: '',    // Search query string
      sortKey: '',        // Column to sort by
      sortAsc: true       // Sort direction: true for ascending, false for descending
    };
  },
  computed: {
    isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
        user() {
          return this.$store.getters.getUser;
        },
        token() {
          return this.$store.getters.getToken;
        },
    filteredAndSortedDemandeOms() {
      let result = this.demandeOms;

      // Filter by search query
      if (this.searchQuery) {
        result = result.filter(demande => {
          return (
            demande.lieu_a_se_rendre.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            demande.lieu_de_provenance.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            demande.objet.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      }

      // Sort by the selected column
      if (this.sortKey) {
        result.sort((a, b) => {
          const valA = a[this.sortKey];
          const valB = b[this.sortKey];

          if (this.sortAsc) {
            return valA > valB ? 1 : valA < valB ? -1 : 0;
          } else {
            return valA < valB ? 1 : valA > valB ? -1 : 0;
          }
        });
      }

      return result;
    }
  },
  async mounted() {
    await this.fetchDemandeOms();
  },
  methods: {
    async fetchDemandeOms() {
      try {
        const response = await api.getDemandeOms();
        this.demandeOms = response.data;
        console.log(this.demandeOms);
      } catch (error) {
        console.error('Failed to fetch demande_oms:', error);
      }
    },
    viewDemandeOm(id) {
      this.$router.push({ name: 'DemandeOmDetail', params: { id } });
    },
    openEditPage(id) {
      this.$router.push({ name: 'EditDemandeOm', params: { id } });
    },
    async validateDemandeOm(id) {
      try {
        await api.validateDemandeOm(id);
        this.fetchDemandeOms();
      } catch (error) {
        console.error('Failed to validate demande_om:', error);
      }
    },
    shouldShowGenerateButton(demande) {
      return (
        (demande.transport_en_commun && demande.is_sd_validate) ||
        (!demande.transport_en_commun && demande.is_sd_validate && demande.is_rlm_validate)
      );
    },
    isDemandeValidate(demande) {
      return (
        (demande.is_sd_validate) )
      ;
    },
    isDemandeNotValidate(demande) {
      return (
        (!demande.is_sd_validate) )
      ;
    },
    generateOm(id) {
      this.$router.push({ name: 'GenerateOm', params: { demandeOmId: id } });
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;  // Toggle sorting direction
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
    formatDate(dateString) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //return new Date(dateString).toLocaleDateString(undefined, options);
        return new Date(dateString).toISOString().substr(0, 10); 
      },
      truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        }
        return text;
      }
  }
};
</script>

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vue3Leaflet from 'vue3-leaflet';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import './registerServiceWorker'
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap';


//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
//import 'bootstrap/dist/css/bootstrap.css'; // Si vous utilisez Bootstrap
//import 'datatables.net-bs4/css/dataTables.bootstrap4.css'; // Si vous utilisez DataTables avec Bootstrap 4

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import DataTables CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';

// Import jQuery
import $ from 'jquery';

// Import DataTables JS
import 'datatables.net';
import 'datatables.net-bs5';
const appInstance = createApp(App);
window.$ = $;
appInstance.use(store);
appInstance.use(router);
appInstance.use(vue3Leaflet);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");

<template>
    <div class="forgot-password">
      <h2>Forgot Password</h2>
      <form @submit.prevent="forgotPassword">
        <div>
          <label for="email">Email</label>
          <input type="email" v-model="email" required>
        </div>
        <button type="submit">Send Reset Link</button>
      </form>
      <p v-if="message">{{ message }}</p>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  
  export default {
    data() {
      return {
        email: '',
        message: '',
        error: ''
      };
    },
    methods: {
      async forgotPassword() {
        try {
          const response = await api.forgotPassword(this.email);
          this.message = response.data.message;
        } catch (error) {
          this.error = 'Error sending reset link';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles spécifiques à ce composant ici */
  </style>
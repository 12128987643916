<!--<template>
  <div v-if="isAuthenticated">
    <h1 class="page-title">Liste des Conducteurs</h1>
    <button class="btn btn-primary" @click="showAddModal">Ajouter Conducteur</button>
    <table id="driversTable" class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nom</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="driver in drivers" :key="driver.id">
          <td>{{ driver.id }}</td>
          <td>{{ getUserInfo(driver.utilisateur_id, 'nom') }}</td>
          <td>{{ getUserInfo(driver.utilisateur_id, 'email') }}</td>
          <td>{{ driver.contact }}</td>
          <td>{{ driver.statut }}</td>
          <td>
            <button @click="showEditModal(driver)" class="btn btn-warning">Éditer</button>
            <button @click="showDeleteModal(driver)" class="btn btn-danger">Supprimer</button>
          </td>
        </tr>
      </tbody>
    </table>

 
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Ajouter Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addDriver">
              <div class="mb-3">
                <label for="user" class="form-label">Utilisateur</label>
                <select id="user" v-model="newDriver.utilisateur_id" class="form-select">
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} ({{ user.email }})</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="contact" class="form-label">Contact</label>
                <input type="text" id="contact" v-model="newDriver.contact" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="statut" class="form-label">Statut</label>
                <input type="text" id="statut" v-model="newDriver.statut" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Ajouter</button>
            </form>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Éditer Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateDriver">
              <div class="mb-3">
                <label for="editUser" class="form-label">Utilisateur</label>
                <select id="editUser" v-model="currentDriver.utilisateur_id" class="form-select">
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} ({{ user.email }})</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="editContact" class="form-label">Contact</label>
                <input type="text" id="editContact" v-model="currentDriver.contact" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="editStatut" class="form-label">Statut</label>
                <input type="text" id="editStatut" v-model="currentDriver.statut" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Supprimer Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Êtes-vous sûr de vouloir supprimer ce conducteur ?</p>
          </div>
          <div class="modal-footer">
            <button @click="deleteDriver" class="btn btn-danger">Supprimer</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import $ from 'jquery';
import 'datatables.net';
import { Modal } from 'bootstrap';

export default {
  name: 'DriversList',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  data() {
    return {
      drivers: [],
      users: [],
      newDriver: {
        utilisateur_id: '',
        contact: '',
        statut: '',
      },
      currentDriver: {
        utilisateur_id: '',
        contact: '',
        statut: '',
      },
      isAddModalOpen: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      dataTable: null,
    };
  },
  async mounted() {
    await this.fetchDrivers();
    await this.fetchUsers();
    this.initDataTable();
  },
  methods: {
    async fetchDrivers() {
      try {
        const response = await api.getDrivers();
        this.drivers = response.data;
        this.resetDataTable();
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    },
    async fetchUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.data;
        this.resetDataTable();
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    },
    initDataTable() {
      this.$nextTick(() => {
        if (!this.dataTable) {
          this.dataTable = $('#driversTable').DataTable();
        }
      });
    },
    resetDataTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = null;
      }
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    getUserInfo(userId, info) {
      const user = this.users.find(user => user.id === userId);
      return user ? user[info] : 'N/A';
    },
    showAddModal() {
      const addModal = new Modal(document.getElementById('addModal'));
      addModal.show();
    },
    async addDriver() {
      try {
        const response = await api.addDriver(this.newDriver);
        this.drivers.push(response.data);
        this.resetDataTable();
        this.newDriver = { utilisateur_id: '', contact: '', statut: '' };
        const addModal = Modal.getInstance(document.getElementById('addModal'));
        addModal.hide();
      } catch (error) {
        console.error('Failed to add driver:', error);
      }
    },
    showEditModal(driver) {
      this.currentDriver = { ...driver };
      const editModal = new Modal(document.getElementById('editModal'));
      editModal.show();
    },
    async updateDriver() {
      try {
        const response = await api.updateDriver(this.currentDriver.id, this.currentDriver);
        const index = this.drivers.findIndex(driver => driver.id === this.currentDriver.id);
        this.drivers.splice(index, 1, response.data);
        console.log("conducteurs apres edit",this.drivers)
        this.resetDataTable();
        const editModal = Modal.getInstance(document.getElementById('editModal'));
        editModal.hide();
      } catch (error) {
        console.error('Failed to update driver:', error);
      }
    },
    showDeleteModal(driver) {
      this.currentDriver = driver;
      const deleteModal = new Modal(document.getElementById('deleteModal'));
      deleteModal.show();
    },
    async deleteDriver() {
      try {
        await api.deleteDriver(this.currentDriver.id);
        this.drivers = this.drivers.filter(driver => driver.id !== this.currentDriver.id);
        this.resetDataTable();
        const deleteModal = Modal.getInstance(document.getElementById('deleteModal'));
        deleteModal.hide();
      } catch (error) {
        console.error('Failed to delete driver:', error);
      }
    },
  },
};
</script>

<style scoped>
.page-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>-->




<template>
  <div v-if="isAuthenticated">
    <h1 class="page-title">GESTIONS DES CONDUCTEURS</h1>
    <button class="btn btn-primary" @click="showAddModal">Ajouter Conducteur</button>
    <input type="text" v-model="searchKeyword" placeholder="Rechercher par Nom ou Email" class="form-control mb-3">
    <table id="driversTable" class="table table-striped">
      <thead>
        <tr>
          <th @click="sortBy('id')">ID</th>
          <th @click="sortBy('nom')">Nom</th>
          <th @click="sortBy('email')">Email</th>
          <th @click="sortBy('contact')">Contact</th>
          <th @click="sortBy('statut')">Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="driver in filteredDrivers" :key="driver.id">
          <td>{{ driver.id }}</td>
          <td>{{ getUserInfo(driver.utilisateur_id, 'nom') }}</td>
          <td>{{ getUserInfo(driver.utilisateur_id, 'email') }}</td>
          <td>{{ driver.contact }}</td>
          <td>{{ driver.statut }}</td>
          <td>
            <button @click="showEditModal(driver)" class="btn btn-warning">Éditer</button>
            <button @click="showDeleteModal(driver)" class="btn btn-danger">Supprimer</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modals restent inchangés -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Ajouter Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addDriver">
              <div class="mb-3">
                <label for="user" class="form-label">Utilisateur</label>
                <select id="user" v-model="newDriver.utilisateur_id" class="form-select">
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} ({{ user.email }})</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="contact" class="form-label">Contact</label>
                <input type="text" id="contact" v-model="newDriver.contact" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="statut" class="form-label">Statut</label>
                <input type="text" id="statut" v-model="newDriver.statut" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Ajouter</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour éditer un conducteur -->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Éditer Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateDriver">
              <div class="mb-3">
                <label for="editUser" class="form-label">Utilisateur</label>
                <select id="editUser" v-model="currentDriver.utilisateur_id" class="form-select">
                  <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }} ({{ user.email }})</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="editContact" class="form-label">Contact</label>
                <input type="text" id="editContact" v-model="currentDriver.contact" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="editStatut" class="form-label">Statut</label>
                <input type="text" id="editStatut" v-model="currentDriver.statut" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour supprimer un conducteur -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Supprimer Conducteur</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Êtes-vous sûr de vouloir supprimer ce conducteur ?</p>
          </div>
          <div class="modal-footer">
            <button @click="deleteDriver" class="btn btn-danger">Supprimer</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { Modal } from 'bootstrap';

export default {
  name: 'DriversList',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    token() {
      return this.$store.getters.getToken;
    },
    filteredDrivers() {
      if (!this.searchKeyword) {
        return this.drivers;
      }
      const keyword = this.searchKeyword.toLowerCase();
      return this.drivers.filter(driver =>
        this.getUserInfo(driver.utilisateur_id, 'nom').toLowerCase().includes(keyword) ||
        this.getUserInfo(driver.utilisateur_id, 'email').toLowerCase().includes(keyword)
      );
    }
  },
  data() {
    return {
      drivers: [],
      users: [],
      newDriver: {
        utilisateur_id: '',
        contact: '',
        statut: '',
      },
      currentDriver: {
        utilisateur_id: '',
        contact: '',
        statut: '',
      },
      isAddModalOpen: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      searchKeyword: '',
    };
  },
  async mounted() {
    await this.fetchDrivers();
    await this.fetchUsers();
  },
  methods: {
    async fetchDrivers() {
      try {
        const response = await api.getDrivers();
        this.drivers = response.data;
      } catch (error) {
        console.error('Failed to fetch drivers:', error);
      }
    },
    async fetchUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.data;
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    },
    sortBy(field) {
      this.drivers.sort((a, b) => {
        const valueA = this.getUserInfo(a.utilisateur_id, field).toLowerCase();
        const valueB = this.getUserInfo(b.utilisateur_id, field).toLowerCase();
        return valueA.localeCompare(valueB);
      });
    },
    getUserInfo(userId, info) {
      const user = this.users.find(user => user.id === userId);
      return user ? user[info] : 'N/A';
    },
    showAddModal() {
      const addModal = new Modal(document.getElementById('addModal'));
      addModal.show();
    },
    async addDriver() {
      try {
        const response = await api.addDriver(this.newDriver);
        this.drivers.push(response.data);
        this.newDriver = { utilisateur_id: '', contact: '', statut: '' };
        const addModal = Modal.getInstance(document.getElementById('addModal'));
        addModal.hide();
      } catch (error) {
        console.error('Failed to add driver:', error);
      }
    },
    showEditModal(driver) {
      this.currentDriver = { ...driver };
      const editModal = new Modal(document.getElementById('editModal'));
      editModal.show();
    },
    async updateDriver() {
      try {
        const response = await api.updateDriver(this.currentDriver.id, this.currentDriver);
        const index = this.drivers.findIndex(d => d.id === this.currentDriver.id);
        if (index !== -1) {
          this.drivers.splice(index, 1, response.data);
        }
        const editModal = Modal.getInstance(document.getElementById('editModal'));
        editModal.hide();
      } catch (error) {
        console.error('Failed to update driver:', error);
      }
    },
    showDeleteModal(driver) {
      this.currentDriver = driver;
      const deleteModal = new Modal(document.getElementById('deleteModal'));
      deleteModal.show();
    },
    async deleteDriver() {
      try {
        await api.deleteDriver(this.currentDriver.id);
        this.drivers = this.drivers.filter(d => d.id !== this.currentDriver.id);
        const deleteModal = Modal.getInstance(document.getElementById('deleteModal'));
        deleteModal.hide();
      } catch (error) {
        console.error('Failed to delete driver:', error);
      }
    },
  },
};
</script>

<style scoped>
.page-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>

<template>
    <div v-if="isAuthenticated">
      <h1 class="page-title">Rapports de la Mission: {{ mission.nom }}</h1>
      <button class="btn btn-primary" @click="goBack">Retour</button>
      <table class="table table-striped mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date du rapport</th>
            <th>Heure de départ</th>
            <th>Heure d'arrivée</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in missionReports" :key="report.id">
            <td>{{ report.id }}</td>
            <td>{{ formatDate(report.reportDate) }}</td>
            <td>{{ formatTime(report.departureTime) }}</td>
            <td>{{ formatTime(report.arrivalTime) }}</td>
            <td>
              <button @click="editReport(report)" class="btn btn-warning">Éditer</button>
              <button @click="deleteReport(report.id)" class="btn btn-danger">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Modal for editing a report -->
      <div class="modal fade" id="editReportModal" tabindex="-1" aria-labelledby="editReportModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editReportModalLabel">Éditer Rapport</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateReport">
                <div class="mb-3">
                  <label for="editReportDate" class="form-label">Date du rapport</label>
                  <input type="date" id="editReportDate" v-model="currentReport.reportDate" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="editDepartureTime" class="form-label">Heure de départ</label>
                  <input type="time" id="editDepartureTime" v-model="currentReport.departureTime" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="editArrivalTime" class="form-label">Heure d'arrivée</label>
                  <input type="time" id="editArrivalTime" v-model="currentReport.arrivalTime" class="form-control" required>
                </div>
                <button type="submit" class="btn btn-primary">Mettre à jour</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Modal for adding a new report -->
      <div class="modal fade" id="addReportModal" tabindex="-1" aria-labelledby="addReportModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addReportModalLabel">Ajouter Rapport</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addReport">
                <div class="mb-3">
                  <label for="newReportDate" class="form-label">Date du rapport</label>
                  <input type="date" id="newReportDate" v-model="newReport.reportDate" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="newDepartureTime" class="form-label">Heure de départ</label>
                  <input type="time" id="newDepartureTime" v-model="newReport.departureTime" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="newArrivalTime" class="form-label">Heure d'arrivée</label>
                  <input type="time" id="newArrivalTime" v-model="newReport.arrivalTime" class="form-control" required>
                </div>
                <button type="submit" class="btn btn-primary">Ajouter</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  import { Modal } from 'bootstrap';
  
  export default {
    name: 'MissionReportsPage',
    data() {
      return {
        missionId: this.$route.params.missionId,
        mission: {},
        missionReports: [],
        currentReport: {},
        newReport: {
          reportDate: '',
          departureTime: '',
          arrivalTime: ''
        }
      };
    },
    computed: {
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      }
    },
    methods: {
      async fetchMission() {
        try {
          const response = await api.getMission(this.missionId);
          this.mission = response.data;
          console.log("LA MISSiON", this.mission)
        } catch (error) {
          console.error('Failed to fetch mission:', error);
        }
      },
      async fetchMissionReports() {
        try {
          console.log("MissION ID",this.missionId)
          const response = await api.getMissionReports(this.missionId);
          this.missionReports = response.data;
        } catch (error) {
          console.error('Failed to fetch mission reports:', error);
        }
      },
      goBack() {
        this.$router.push('/missions');
      },
      showAddModal() {
        const addReportModal = new Modal(document.getElementById('addReportModal'));
        addReportModal.show();
      },
      editReport(report) {
        this.currentReport = { ...report };
        const editReportModal = new Modal(document.getElementById('editReportModal'));
        editReportModal.show();
      },
      async addReport() {
        try {
          const response = await api.addMissionReport(this.missionId, this.newReport);
          this.missionReports.push(response.data);
          this.newReport = {
            reportDate: '',
            departureTime: '',
            arrivalTime: ''
          };
          const addReportModal = Modal.getInstance(document.getElementById('addReportModal'));
          addReportModal.hide();
        } catch (error) {
          console.error('Failed to add report:', error);
        }
      },
      async updateReport() {
        try {
          const response = await api.updateMissionReport(this.currentReport.id, this.currentReport);
          const index = this.missionReports.findIndex(report => report.id === this.currentReport.id);
          if (index !== -1) {
            this.missionReports.splice(index, 1, response.data);
          }
          const editReportModal = Modal.getInstance(document.getElementById('editReportModal'));
          editReportModal.hide();
        } catch (error) {
          console.error('Failed to update report:', error);
        }
      },
      async deleteReport(reportId) {
        try {
          await api.deleteMissionReport(reportId);
          this.missionReports = this.missionReports.filter(report => report.id !== reportId);
        } catch (error) {
          console.error('Failed to delete report:', error);
        }
      },
      formatDate(dateString) {
        return new Date(dateString).toLocaleDateString();
      },
      formatTime(timeString) {
        return new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
    },
    async mounted() {
      await this.fetchMission();
      await this.fetchMissionReports();
    }
  };
  </script>
  
  <style>
  .page-title {
    text-align: center;
    margin: 20px 0;
  }
  </style>
  


<script>
/*import api from '@/services/api';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await api.login({ email: this.email, mot_de_passe: this.password });
        console.log(response.data.token)
        // Handle the login success (e.g., save the token, redirect to a protected route)
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
  }
};*/
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      error: '',
    
    };
  },
  computed: {
    ...mapState(['isAuthenticated']),
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        console.log("email");
        console.log(this.email);
        const credentials = ({email: this.email, mot_de_passe: this.password});
        console.log("cr");
        console.log("cr",credentials);
        const response2 =await this.login(credentials);

        console.log("R2",response2.response.status);
        if(response2.response.status=="404"){
            this.error="Mot de passe ou email incorrect"
        }
       

        // Redirection ou autre logique après connexion réussie
      } catch (error) {
        console.log("ERREUR 2")
        //console.error(error);
        /*this.error = error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred';*/
        
      }
    }
  },
};
</script>

<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName("body")[0];

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Connexion</h4>
                  <p class="mb-0">Entrer votre email et votre mot de passe</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="handleLogin" role="form">
                    <div class="mb-3">
                      <argon-input
                        v-model="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me"
                      >Se rappeller de moi</argon-switch
                    >

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"

                        >Connexion</argon-button
                      >
                    </div>
                  </form>
                </div>
                <p v-if="error">{{error}}</p>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Avez-vous un compte?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                      >Inscription</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            
              >
              <div
                class="position-relative bg-gradient-secondary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{ backgroundImage: 'url(' + require('@/assets/img/logos/Logo_dedras2.png') + ')', backgroundSize:'contain' ,backgroundRepeat:'no-repeat'}"
              >
                <!--<span class="mask bg-gradient-primary opacity-6"></span>-->
                <span class="mask bg-gradient-secondary opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Mission Driver Dedras-ONG"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

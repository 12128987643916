<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import api from '@/services/api';
import { ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'Statistics',
  components: {
    MiniStatisticsCard,
  },
  setup() {
    const statistics = ref({});
    const chartInstance1 = ref(null);
    //const chartInstance2 = ref(null);
    const totalMissions = ref(0);
    const totalVehicles = ref(0);
    const totalDrivers = ref(0);
    const averageMissionsPerVehicle = ref(0);
    const averageMissionsPerDriver = ref(0);
    const chart1 = ref(null);
    const chart2 = ref(null);

    const fetchStatistics = async (period) => {
      try {
        const response = await api.getStatistics(period);
        statistics.value = response.data;
        renderChart();
      //  renderChart2();
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    const stats = async () => {
      try {
        const missionsResponse = await api.getTotalMissions();
        totalMissions.value = missionsResponse.data.totalMissions;
        console.log("Total mission", totalMissions.value);

        const vehiclesResponse = await api.getTotalVehicles();
        totalVehicles.value = vehiclesResponse.data.totalVehicules;
        console.log("Total vehicules", totalVehicles.value);

        const driversResponse = await api.getTotalConducteurs();
        totalDrivers.value = driversResponse.data.totalConducteurs;
        console.log("Total conducteurs", totalDrivers.value);

        const averagesResponse = await api.getGlobalAverages();
        averageMissionsPerVehicle.value = averagesResponse.data.averageMissionsPerVehicule;
        averageMissionsPerDriver.value = averagesResponse.data.averageMissionsPerConducteur;
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    };

    const renderChart = () => {
      if (chartInstance1.value) {
        chartInstance1.value.destroy();
      }
      if (chart1.value) {
        const ctx = chart1.value.getContext('2d');
        chartInstance1.value = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: Object.keys(statistics.value),
            datasets: [
              {
                label: 'Nombre de Missions',
                data: Object.values(statistics.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    };

    /*const renderChart2 = () => {
      if (chartInstance2.value) {
        chartInstance2.value.destroy();
      }
      if (chart2.value) {
        const ctx = chart2.value.getContext('2d');
        chartInstance2.value = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: Object.keys(statistics.value),
            datasets: [
              {
                label: 'Nombre de Missions',
                data: Object.values(statistics.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    };*/

    onMounted(() => {
      fetchStatistics('year');
      stats(); // Exemple : Récupérer les statistiques annuelles par défaut
    });

    return {
      statistics,
      fetchStatistics,

      totalMissions,
      totalVehicles,
      totalDrivers,
      averageMissionsPerVehicle,
      averageMissionsPerDriver,
      chart1,
      chart2,
    };
  },
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Nombre de missions"
              :value="totalMissions"
              description="<span class='text-sm font-weight-bolder text-success'></span>"
              :icon="{
                component: 'fa fa-tasks',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Nombre de Conducteurs"
              :value="totalDrivers"
              description="<span class='text-sm font-weight-bolder text-success'></span>"
              :icon="{
                component: 'fa fa-male',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Nombre de Véhicules"
              :value="totalVehicles"
              description="<span class='text-sm font-weight-bolder text-danger'></span>"
              :icon="{
                component: 'fa fa-car',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Moyenne par Conducteur"
              :value="averageMissionsPerDriver"
              description="<span class='text-sm font-weight-bolder text-success'></span>"
              :icon="{
                component: 'fa fa-bar-chart',
                background: 'bg-gradient-warning',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <div>
                <h2>Statistiques des Missions par Conducteur</h2>
                <div class="btn-group mb-4" role="group">
                  <button @click="fetchStatistics('year')" class="btn btn-primary">Année</button>
                  <button @click="fetchStatistics('month')" class="btn btn-secondary">Mois</button>
                  <button @click="fetchStatistics('week')" class="btn btn-success">Semaine</button>
                  <button @click="fetchStatistics('previous_week')" class="btn btn-info">Semaine Précédente</button>
                </div>
                <canvas id="myChart" ref="chart1"></canvas>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="row">
          <div class="col-lg-12 mb-lg">
            <div class="card z-index-2">
              <div>
                <h2>Statistiques des Missions par Conducteur</h2>
                <div class="btn-group mb-4" role="group">
                  <button @click="fetchStatistics('year')" class="btn btn-primary">Année</button>
                  <button @click="fetchStatistics('month')" class="btn btn-secondary">Mois</button>
                  <button @click="fetchStatistics('week')" class="btn btn-success">Semaine</button>
                  <button @click="fetchStatistics('previous_week')" class="btn btn-info">Semaine Précédente</button>
                </div>
                <canvas id="myChart2" ref="chart2"></canvas>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>
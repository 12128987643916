<template>
  <div class="register">
    <h2>Inscription</h2>
    <form @submit.prevent="register">
      <div>
        <label for="name">Nom</label>
        <input type="text" v-model="name" required>
      </div>
      <div>
        <label for="email">Email</label>
        <input type="email" v-model="email" required>
      </div>
      <div>
        <label for="password">Mot de passe</label>
        <input type="password" v-model="password" required>
      </div>
      <div>
        <label for="confirmPassword">Confirmez le mot de passe</label>
        <input type="password" v-model="confirmPassword" required>
      </div>
      <button type="submit">S'inscrire</button>
    </form>
    <p v-if="message">{{ message }}</p>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'RegisterView',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      message: '',
      error: ''
    };
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        this.error = 'Les mots de passe ne correspondent pas';
        return;
      }
      
      try {
        const response = await api.post('/register', {
          name: this.name,
          email: this.email,
          password: this.password
        });
        this.message = response.data.message;
      } catch (error) {
        this.error = 'Erreur lors de l\'inscription';
      }
    }
  }
};
</script>

<style scoped>
.register {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.register h2 {
  text-align: center;
}

.register form {
  display: flex;
  flex-direction: column;
}

.register form div {
  margin-bottom: 15px;
}

.register form label {
  margin-bottom: 5px;
}

.register form input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register button {
  padding: 10px;
  background-color: #4DBA87;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register button:hover {
  background-color: #45a176;
}
</style>
<template>
    <div>
      <h2>Statistiques des Missions par Conducteur</h2>
      <div>
        <button @click="fetchStatistics('year')">Année</button>
        <button @click="fetchStatistics('month')">Mois</button>
        <button @click="fetchStatistics('week')">Semaine</button>
        <button @click="fetchStatistics('previous_week')">Semaine Précédente</button>
      </div>
      <canvas id="myChart"></canvas>
    </div>
  </template>
  
  <script>
  //import axios from 'axios';
  import api from '@/services/api';
  import { ref, onMounted } from 'vue';
  import { Chart, registerables } from 'chart.js';
  Chart.register(...registerables);
  
  export default {
    name: 'Statistics',
    setup() {
      const statistics = ref({});
      const chartInstance = ref(null);
  
      const fetchStatistics = async (period) => {
        try {
          const response = await api.getStatistics(period);
          statistics.value = response.data;
          renderChart();
        } catch (error) {
          console.error('Error fetching statistics:', error);
        }
      };
  
      const renderChart = () => {
        if (chartInstance.value) {
          chartInstance.value.destroy();
        }
        const ctx = document.getElementById('myChart').getContext('2d');
        chartInstance.value = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: Object.keys(statistics.value),
            datasets: [
              {
                label: 'Nombre de Missions',
                data: Object.values(statistics.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      };
  
      onMounted(() => {
        fetchStatistics('year'); // Example: Fetch yearly statistics by default
      });
  
      return { statistics, fetchStatistics };
    },
  };
  </script>
<script setup>
import { reactive, watch } from 'vue';

const props = defineProps({
  rowReverse: {
    type: Boolean,
    default: false,
  },
  title: {
    type: [Object, String],
    required: true,
    default: () => ({
      text: "",
      color: "",
    }),
  },
  description: {
    type: String,
    default: "",
  },
  value: {
    type: [Object, String, Number],
    required: true,
    default: () => ({
      text: "",
      color: "",
    }),
  },
  percentage: {
    type: [Object, String],
    default: () => ({
      value: "",
      color: "success",
    }),
  },
  icon: {
    type: [String, Object],
    default: () => ({
      component: "",
      background: "bg-white",
      shape: "border-radius-md",
    }),
  },
  classContent: {
    type: String,
    default: "",
  },
});

const computedTitle = reactive({
  text: typeof props.title === "string" ? props.title : props.title.text,
  color: typeof props.title === "object" ? props.title.color : "",
});

const computedValue = reactive({
  text: typeof props.value === "string" || typeof props.value === "number" ? props.value : props.value.text,
  color: typeof props.value === "object" ? props.value.color : "",
});

const computedPercentage = reactive({
  value: typeof props.percentage === "string" ? props.percentage : props.percentage.value,
  color: typeof props.percentage === "object" ? props.percentage.color : "",
});

// Watch props and update reactive objects accordingly
watch(() => props.title, (newValue) => {
  computedTitle.text = typeof newValue === "string" ? newValue : newValue.text;
  computedTitle.color = typeof newValue === "object" ? newValue.color : "";
});

watch(() => props.value, (newValue) => {
  computedValue.text = typeof newValue === "string" || typeof newValue === "number" ? newValue : newValue.text;
  computedValue.color = typeof newValue === "object" ? newValue.color : "";
});

watch(() => props.percentage, (newValue) => {
  computedPercentage.value = typeof newValue === "string" ? newValue : newValue.value;
  computedPercentage.color = typeof newValue === "object" ? newValue.color : "";
});
</script>

<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div
        class="d-flex"
        :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
      >
        <div
          class="text-center shadow icon icon-shape"
          :class="[
            typeof icon === 'object'
              ? `${icon.background} ${icon.shape}`
              : 'border-radius-md',
            rowReverse ? 'me-2' : '',
          ]"
        >
          <i
            class="text-lg opacity-10"
            :class="typeof icon === 'string' ? icon : icon.component"
            aria-hidden="true"
          ></i>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p
              class="mb-0 text-sm text-uppercase font-weight-bold"
              :class="computedTitle.color"
            >
              {{ computedTitle.text }}
            </p>
            <h5 :class="`mb-0 font-weight-bolder ${computedValue.color}`">
              {{ computedValue.text }}
              <span
                v-if="percentage && typeof percentage === 'string'"
                class="text-sm font-weight-bolder"
              >
                {{ percentage }}
              </span>
              <span
                v-if="percentage && typeof percentage === 'object'"
                :class="`text-sm font-weight-bolder text-${computedPercentage.color}`"
              >
                {{ computedPercentage.value }}
              </span>
            </h5>
            <p v-if="description" class="mt-2 mb-0" v-html="description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div>
    <h1>Tracker Page</h1>
    <button @click="requestPermission">Activer la géolocalisation</button>
    <p v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'TrackerView',
  data() {
    return {
      errorMessage: '',
      driverId: 1, // Remplacez par la méthode appropriée pour obtenir l'ID du conducteur
      missionId: 1 // Remplacez par la méthode appropriée pour obtenir l'ID de la mission
    };
  },
  methods: {
    requestPermission() {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
          if (permissionStatus.state === 'granted') {
            this.startTracking();
          } else if (permissionStatus.state === 'prompt') {
            this.promptForPermission();
          } else {
            this.errorMessage = "La géolocalisation est désactivée dans les paramètres de votre navigateur.";
          }
        });
      } else {
        this.startTracking();
      }
    },
    promptForPermission() {
      navigator.geolocation.getCurrentPosition(
        () => {
          this.startTracking();
        },
        (error) => {
          this.handleError(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    },
    startTracking() {
      navigator.geolocation.watchPosition(
        this.sendLocation,
        this.handleError,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    },
    async sendLocation(position) {
      const { latitude, longitude } = position.coords;

      try {
        await api.tracker({
          conducteur_id: this.driverId,
          mission_id: this.missionId,
          latitude: latitude,
          longitude: longitude
        });
      } catch (error) {
        console.error('Failed to send location', error);
      }
    },
    handleError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.errorMessage = "L'utilisateur a refusé la demande de géolocalisation.";
          break;
        case error.POSITION_UNAVAILABLE:
          this.errorMessage = "Les informations de localisation ne sont pas disponibles.";
          break;
        case error.TIMEOUT:
          this.errorMessage = "La demande de géolocalisation a expiré.";
          break;
        case error.UNKNOWN_ERROR:
          this.errorMessage = "Une erreur inconnue est survenue.";
          break;
      }
    }
  }
};
</script>

<style>
/* Votre style ici */
</style>
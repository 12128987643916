<template>
    <div v-if="isAuthenticated">
      <h1 class="page-title">GESTION DES PARTICIPANTS</h1>
      <button class="btn btn-primary" @click="showAddModal">Ajouter un Participant</button>
  
      <!-- Champ de recherche -->
      <div class="mt-3">
        <label for="search" class="form-label">Rechercher</label>
        <input type="text" id="search" v-model="searchQuery" class="form-control" placeholder="Rechercher...">
      </div>
  
      <table class="table table-striped mt-3" v-if="filteredParticipants && filteredParticipants.length > 0">
        <thead>
          <tr>
            <th @click="sortBy('id')">ID</th>
            <th @click="sortBy('noms')">Nom</th>
            <th @click="sortBy('fonction')">Fonction</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="participant in filteredParticipants" :key="participant.id">
            <td>{{ participant.id }}</td>
            <td>{{ participant.noms }}</td>
            <td>{{ participant.fonction }}</td>
            <td>
              <button @click="showEditModal(participant)" class="btn btn-warning">Éditer</button>
              <button @click="showDeleteModal(participant)" class="btn btn-danger">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <div v-if="filteredParticipants.length === 0 && participants.length > 0" class="mt-3">
        Aucun résultat trouvé.
      </div>
  
      <!-- Modal pour ajouter un participant -->
      <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Ajouter Participant</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addParticipant">
                <div class="mb-3">
                  <label for="noms" class="form-label">Nom</label>
                  <input type="text" id="noms" v-model="newParticipant.noms" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="fonction" class="form-label">Fonction</label>
                  <input type="text" id="fonction" v-model="newParticipant.fonction" class="form-control" required>
                </div>
                <button type="submit" class="btn btn-primary">Ajouter</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Modal pour éditer un participant -->
      <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editModalLabel">Éditer Participant</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateParticipant">
                <div class="mb-3">
                  <label for="editNoms" class="form-label">Nom</label>
                  <input type="text" id="editNoms" v-model="currentParticipant.noms" class="form-control" required>
                </div>
                <div class="mb-3">
                  <label for="editFonction" class="form-label">Fonction</label>
                  <input type="text" id="editFonction" v-model="currentParticipant.fonction" class="form-control" required>
                </div>
                <button type="submit" class="btn btn-primary">Enregistrer</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Modal pour supprimer un participant -->
      <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteModalLabel">Supprimer Participant</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Êtes-vous sûr de vouloir supprimer ce participant ?</p>
            </div>
            <div class="modal-footer">
              <button @click="deleteParticipant" class="btn btn-danger">Supprimer</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  import { Modal } from 'bootstrap';
  
  export default {
    name: 'participantsList',
    computed: {
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      },
      user() {
        return this.$store.getters.getUser;
      },
      token() {
        return this.$store.getters.getToken;
      }
    },
    data() {
      return {
        participants: [],
        filteredParticipants: [],
        newParticipant: {
          noms: '',
          fonction: ''
        },
        currentParticipant: {
          noms: '',
          fonction: ''
        },
        searchQuery: ''
      };
    },
    async mounted() {
      await this.fetchParticipants();
    },
    watch: {
      participants: {
        handler() {
          this.applySearch();
        },
        deep: true
      },
      searchQuery() {
        this.applySearch();
      }
    },
    methods: {
      async fetchParticipants() {
        try {
          const response = await api.getParticipants();
          this.participants = response.data;
        } catch (error) {
          console.error('Failed to fetch participants:', error);
        }
      },
      showAddModal() {
        const addModal = new Modal(document.getElementById('addModal'));
        addModal.show();
      },
      async addParticipant() {
        try {
          const response = await api.addParticipant(this.newParticipant);
          this.participants.push(response.data);
          const addModal = Modal.getInstance(document.getElementById('addModal'));
          addModal.hide();
        } catch (error) {
          console.error('Failed to add participant:', error);
        }
      },
      showEditModal(participant) {
        this.currentParticipant = { ...participant };
        const editModal = new Modal(document.getElementById('editModal'));
        editModal.show();
      },
      async updateParticipant() {
        try {
          const response = await api.updateParticipant(this.currentParticipant.id, this.currentParticipant);
          const index = this.participants.findIndex(p => p.id === this.currentParticipant.id);
          if (index !== -1) {
            this.participants.splice(index, 1, response.data);
          }
          const editModal = Modal.getInstance(document.getElementById('editModal'));
          editModal.hide();
        } catch (error) {
          console.error('Failed to update participant:', error);
        }
      },
      showDeleteModal(participant) {
        this.currentParticipant = participant;
        const deleteModal = new Modal(document.getElementById('deleteModal'));
        deleteModal.show();
      },
      async deleteParticipant() {
        try {
          await api.deleteParticipant(this.currentParticipant.id);
          this.participants = this.participants.filter(p => p.id !== this.currentParticipant.id);
          const deleteModal = Modal.getInstance(document.getElementById('deleteModal'));
          deleteModal.hide();
        } catch (error) {
          console.error('Failed to delete participant:', error);
        }
      },
      applySearch() {
        if (this.searchQuery.trim() === '') {
          this.filteredParticipants = [...this.participants];
        } else {
          const query = this.searchQuery.toLowerCase();
          this.filteredParticipants = this.participants.filter(participant =>
            participant.noms.toLowerCase().includes(query) ||
            participant.fonction.toLowerCase().includes(query)
          );
        }
      },
      sortBy(key) {
        this.filteredParticipants.sort((a, b) => {
          const x = typeof a[key] === 'string' ? a[key] : '';
          const y = typeof b[key] === 'string' ? b[key] : '';
          return x.localeCompare(y);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .page-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  </style>
  
<template>
    <div class="reset-password">
      <h2>Reset Password</h2>
      <form @submit.prevent="resetPassword">
        <div>
          <label for="password">New Password</label>
          <input type="password" v-model="password" required>
        </div>
        <div>
          <label for="confirmPassword">Confirm Password</label>
          <input type="password" v-model="confirmPassword" required>
        </div>
        <button type="submit">Reset Password</button>
      </form>
      <p v-if="message">{{ message }}</p>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  
  export default {
    data() {
      return {
        password: '',
        confirmPassword: '',
        message: '',
        error: ''
      };
    },
    methods: {
      async resetPassword() {
        if (this.password !== this.confirmPassword) {
          this.error = 'Passwords do not match';
          return;
        }
  
        const token = this.$route.query.token;
        if (!token) {
          this.error = 'Invalid token';
          return;
        }
  
        try {
          const response = await api.resetPassword({ token, password: this.password });
          this.message = response.data.message;
        } catch (error) {
          this.error = 'Error resetting password';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles spécifiques à ce composant ici */
  </style>
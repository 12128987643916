<template>
  <div>
    <h1>Positions de la Mission</h1>
    <div id="map" style="height: 600px; width: 100%;"></div>
  </div>
</template>

<script>
import L from "leaflet";
import { Icon }  from 'leaflet'
import api from "@/services/api";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";
// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "MissionPositions",
  data() {
    return {
      icon: L.icon({
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        iconSize: [38, 95],
        iconAnchor: [22, 94]
      }),
      missionId: this.$route.params.missionId,
      positions: [],
      map: null,
      markers: [],
      intervalId: null,
    };
  },
  methods: {
    async fetchPositions() {
      try {
        const response = await api.getMissionPositions(this.missionId);
        this.positions = response.data;
        console.log("Positions:", this.positions);
        this.updatePositions();
      } catch (error) {
        console.error("Failed to fetch positions:", error);
      }
    },
    initializeMap() {
      this.map = L.map("map").setView([48.8566, 2.3522], 13);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
      }).addTo(this.map);
    },
    updatePositions() {
      // Clear existing markers
      this.markers.forEach(marker => this.map.removeLayer(marker));
      this.markers = [];

      // Add new markers
      this.positions.forEach(position => {
        const marker = L.marker([position.latitude, position.longitude])
          .bindPopup(position.timestamp)
          .addTo(this.map);
        this.markers.push(marker);
      });

      // Update map center
      if (this.positions.length > 0) {
        const center = [this.positions[0].latitude, this.positions[0].longitude];
        this.map.setView(center, 13);
      }
    },
  },
  mounted() {
    this.initializeMap();
    this.fetchPositions();
  },
};
</script>

<style>
@import "leaflet/dist/leaflet.css";

</style>

<template>
  <div v-if="isAuthenticated">
    <h1 class="page-title">GESTION DES VEHICULES</h1>
    <button class="btn btn-primary" @click="showAddModal">Ajouter un Véhicule</button>
    
    <!-- Champ de recherche -->
    <div class="mt-3">
      <label for="search" class="form-label">Rechercher</label>
      <input type="text" id="search" v-model="searchQuery" class="form-control" placeholder="Rechercher...">
    </div>
    
    <table class="table table-striped mt-3" v-if="filteredVehicles && filteredVehicles.length > 0">
      <thead>
        <tr>
          <th @click="sortBy('id')">ID</th>
          <th @click="sortBy('marque')">Marque</th>
          <th @click="sortBy('modele')">Modèle</th>
          <th @click="sortBy('immatriculation')">Immatriculation</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="vehicle in filteredVehicles" :key="vehicle.id">
          <td>{{ vehicle.id }}</td>
          <td>{{ vehicle.marque }}</td>
          <td>{{ vehicle.modele }}</td>
          <td>{{ vehicle.immatriculation }}</td>
          <td>
            <button @click="showEditModal(vehicle)" class="btn btn-warning">Éditer</button>
            <button @click="showDeleteModal(vehicle)" class="btn btn-danger">Supprimer</button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="filteredVehicles.length === 0 && vehicles.length > 0" class="mt-3">
      Aucun résultat trouvé.
    </div>

    <!-- Modal pour ajouter un conducteur -->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalLabel">Ajouter Véhicule</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addVehicle">
              <div class="mb-3">
                <label for="marque" class="form-label">Marque</label>
                <input type="text" id="marque" v-model="newVehicle.marque" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="modele" class="form-label">Modèle</label>
                <input type="text" id="modele" v-model="newVehicle.modele" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="immatriculation" class="form-label">Immatriculation</label>
                <input type="text" id="immatriculation" v-model="newVehicle.immatriculation" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Ajouter</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour éditer un conducteur -->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Éditer Véhicule</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateVehicle">
              <div class="mb-3">
                <label for="editMarque" class="form-label">Marque</label>
                <input type="text" id="editMarque" v-model="currentVehicle.marque" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="editModele" class="form-label">Modèle</label>
                <input type="text" id="editModele" v-model="currentVehicle.modele" class="form-control" required>
              </div>
              <div class="mb-3">
                <label for="editImmatriculation" class="form-label">Immatriculation</label>
                <input type="text" id="editImmatriculation" v-model="currentVehicle.immatriculation" class="form-control" required>
              </div>
              <button type="submit" class="btn btn-primary">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour supprimer un conducteur -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Supprimer Véhicule</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Êtes-vous sûr de vouloir supprimer ce véhicule ?</p>
          </div>
          <div class="modal-footer">
            <button @click="deleteVehicle" class="btn btn-danger">Supprimer</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { Modal } from 'bootstrap';

export default {
  name: 'vehiclesList',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.getUser;
    },
    token() {
      return this.$store.getters.getToken;
    }
  },
  data() {
    return {
      vehicles: [],
      filteredVehicles: [],
      newVehicle: {
        marque: '',
        modele: '',
        immatriculation: ''
      },
      currentVehicle: {
        marque: '',
        modele: '',
        immatriculation: ''
      },
      searchQuery: ''
    };
  },
  async mounted() {
    await this.fetchvehicles();
  },
  watch: {
    vehicles: {
      handler() {
        this.applySearch();
      },
      deep: true
    },
    searchQuery() {
      this.applySearch();
    }
  },
  methods: {
    async fetchvehicles() {
      try {
        const response = await api.getVehicles();
        this.vehicles = response.data;
      } catch (error) {
        console.error('Failed to fetch vehicles:', error);
      }
    },
    showAddModal() {
      const addModal = new Modal(document.getElementById('addModal'));
      addModal.show();
    },
    async addVehicle() {
      try {
        const response = await api.addVehicle(this.newVehicle);
        this.vehicles.push(response.data);
        const addModal = Modal.getInstance(document.getElementById('addModal'));
        addModal.hide();
      } catch (error) {
        console.error('Failed to add vehicle:', error);
      }
    },
    showEditModal(vehicle) {
      this.currentVehicle = { ...vehicle };
      const editModal = new Modal(document.getElementById('editModal'));
      editModal.show();
    },
    async updateVehicle() {
      try {
        const response = await api.updateVehicle(this.currentVehicle.id, this.currentVehicle);
        const index = this.vehicles.findIndex(v => v.id === this.currentVehicle.id);
        if (index !== -1) {
          this.vehicles.splice(index, 1, response.data);
        }
        const editModal = Modal.getInstance(document.getElementById('editModal'));
        editModal.hide();
      } catch (error) {
        console.error('Failed to update vehicle:', error);
      }
    },
    showDeleteModal(vehicle) {
      this.currentVehicle = vehicle;
      const deleteModal = new Modal(document.getElementById('deleteModal'));
      deleteModal.show();
    },
    async deleteVehicle() {
      try {
        await api.deleteVehicle(this.currentVehicle.id);
        this.vehicles = this.vehicles.filter(v => v.id !== this.currentVehicle.id);
        const deleteModal = Modal.getInstance(document.getElementById('deleteModal'));
        deleteModal.hide();
      } catch (error) {
        console.error('Failed to delete vehicle:', error);
      }
    },
    applySearch() {
      if (this.searchQuery.trim() === '') {
        this.filteredVehicles = [...this.vehicles];
      } else {
        const query = this.searchQuery.toLowerCase();
        this.filteredVehicles = this.vehicles.filter(vehicle =>
          vehicle.marque.toLowerCase().includes(query) ||
          vehicle.modele.toLowerCase().includes(query) ||
          vehicle.immatriculation.toLowerCase().includes(query)
        );
      }
    },
    sortBy(key) {
  this.filteredVehicles.sort((a, b) => {
    const x = typeof a[key] === 'string' ? a[key] : '';
    const y = typeof b[key] === 'string' ? b[key] : '';
    return x.localeCompare(y);
  });
}
  }
};
</script>

<style scoped>
.page-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>

<template>
    <div>
    <h2 class="text-xl font-weight-bold mb-0 text-center" style="background-color: wheat;">GENERATION D'OM</h2>
    <div class="row"  v-if="demandeOm">
      
      <h2 class="text-sm  mb-0 text-center" style="background-color: wheat;">Pour Demande d'Odre de Mission: {{ demandeOm.objet }}</h2>
      <!--<button @click="generateSingleOm">Générer un OM</button>
      <button @click="generateMultipleOms">Générer plusieurs OMs</button>-->
      <div class="col-md-6" style="background-color: wheat;">
        <h2 class="text-lg font-weight-bold mb-0 text-center" style="color:navy"> <button class="btn btn-success" @click="generateSingleOm">Générer un OM</button></h2>
        
      </div>
      <div class="col-md-6" style="background-color: wheat;">
        <h2 class="text-lg font-weight-bold mb-0 text-center" style="color:navy"><button class="btn btn-success" @click="generateMultipleOms">Générer plusieurs OMs</button></h2>
      </div>
    </div>
    <div style="background-color: wheat;">
   
    <button @click="goBack" class="btn btn-secondary">Retour</button>
    </div>
    <LoadingModal :showModal="isLoading" />
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  import LoadingModal from '@/views/components/LoadingModal.vue';
  
  export default {
    name: 'GenerateOm',
    components: {
      LoadingModal
     },
    data() {
      return {
        demandeOmId: this.$route.params.demandeOmId,
        demandeOm: null,
        isLoading: false 
      };
    },
    async created() {
    await this.fetchDemandeOm();
  },
    methods: {
      async fetchDemandeOm() {
      const id = this.$route.params.demandeOmId;
      try {
        const response = await api.getDemandeOm(id);
        this.demandeOm = response.data;
      } catch (error) {
        console.error('Failed to fetch demande_om:', error);
      }
    },
      async generateSingleOm() {
        try {
          await api.generateSingleOm(this.demandeOmId);
          this.$router.push({ name: 'ListeOms', params: { demandeOmId: this.demandeOmId } });
        } catch (error) {
          console.error('Failed to generate single OM:', error);
        }
      },
      async generateMultipleOms() {
        try {
          await api.generateMultipleOms(this.demandeOmId);
          this.$router.push({ name: 'ListeOms', params: { demandeOmId: this.demandeOmId } });
        } catch (error) {
          console.error('Failed to generate multiple OMs:', error);
        }
      },
      goBack() {
      this.isLoading = true;   
      setTimeout(() => {
          this.isLoading = false;
          this.$router.push({ name: 'ListeDemandeOms' });
        }, 3000);
      
    },
    }
  };
  </script>
  
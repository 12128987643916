<template>
  <div class="container" style="background-color: wheat;">
    <h1>Modifier Demande OM</h1>
    <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
      <!-- Champs existants -->
      <div class="mb-3">
        <label for="lieu_a_se_rendre" class="form-label">Lieu à se rendre</label>
        <input type="text" id="lieu_a_se_rendre" v-model="form.lieu_a_se_rendre" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="lieu_de_provenance" class="form-label">Lieu de provenance</label>
        <input type="text" id="lieu_de_provenance" v-model="form.lieu_de_provenance" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="objet" class="form-label">Objet</label>
        <input type="text" id="objet" v-model="form.objet" class="form-control" required>
      </div>
      <div class="mb-3">
<label for="nombre_de_jours" class="form-label">Nombre de jours</label>
<input type="number" id="nombre_de_jours" v-model="form.nombre_de_jours" class="form-control" required>
</div>
      <div class="mb-3">
        <label for="date_de_depart" class="form-label">Date de départ</label>
        <input type="date" id="date_de_depart" v-model="form.date_de_depart" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="date_de_retour" class="form-label">Date de retour</label>
        <input type="date" id="date_de_retour" v-model="form.date_de_retour" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="transport_en_commun" class="form-label">Transport en commun</label>
        <input type="checkbox" id="transport_en_commun" v-model="form.transport_en_commun" class="form-check-input">
      </div>
      
      <!-- Recherche de participants -->
      <div class="mb-3">
        <label for="search" class="form-label">Rechercher Participant</label>
        <input type="text" v-model="participantSearch" @input="filterParticipants" class="form-control" placeholder="Rechercher...">
      </div>

      <div class="row">
  <!-- Liste des participants non sélectionnés -->
  <div class="col-md-5 border p-3">
  <label for="inactive-participants" class="form-label">Participants Inactifs</label>
  <div v-for="participant in availableParticipants" :key="participant.id" class="form-check">
    
    <input type="checkbox" :id="'inactive-' + participant.id" :value="participant.id" v-model="selectedInactiveParticipants" class="form-check-input">
    <label :for="'inactive-' + participant.id" class="form-check-label" style="padding-left: 16px;">{{ participant.noms }}</label>
  </div>
</div>

  <!-- Boutons Ajouter et Retirer -->
  <div class="col-md-2 d-flex align-items-center justify-content-center border p-3">
    <div class="d-flex flex-column">
      <button 
        type="button" 
        @click="activateParticipants" 
        class="btn btn-success mb-2"
        :disabled="!selectedInactiveParticipants.length">
        Ajouter
      </button>
      <button 
        type="button" 
        @click="deactivateParticipants" 
        class="btn btn-danger"
        :disabled="!selectedActiveParticipants.length">
        Retirer
      </button>
    </div>
  </div>

  <!-- Liste des participants sélectionnés -->
  <div class="col-md-5 border p-3">
    <label for="active-participants" class="form-label">Participants Actifs</label>
    <div v-for="participant in activeParticipants" :key="participant.id" class="form-check">
      <input type="checkbox" :id="'active-' + participant.id" :value="participant.id" v-model="selectedActiveParticipants" class="form-check-input">
      <label :for="'active-' + participant.id" class="form-check-label">{{ participant.noms }}</label>
    </div>
  </div>
</div>


      <!-- Champ de fichier TDR -->
      <div class="mb-3">
        <label for="tdr" class="form-label">Fichier TDR</label>
        <input type="file" id="tdr" @change="handleFileUpload" class="form-control">
      </div>
      
      <button type="submit" class="btn btn-primary">Modifier</button>
      <button @click="goBack" class="btn btn-secondary">Retour</button>
    </form>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'EditDemandeOmModal',
  data() {
    return {
      form: {
        lieu_a_se_rendre: '',
        lieu_de_provenance: '',
        nombre_de_jours:'',
        objet: '',
        date_de_depart: '',
        date_de_retour: '',
        transport_en_commun: false,
        participants: [],
        tdr: null
      },
      participants: [],
      participantSearch: '',
      availableParticipants: [],
      activeParticipants: [],
      selectedInactiveParticipants: [],
      selectedActiveParticipants: [],
      
    };
  },
  async created() {
    await this.fetchDemandeOm();
    await this.fetchParticipants();
  },
  methods: {
    async fetchDemandeOm() {
      const id = this.$route.params.id;
      try {
        const response = await api.getDemandeOm(id);
        //Object.assign(this.form, response.data);
        this.form.lieu_a_se_rendre = response.data.lieu_a_se_rendre || '';
    this.form.lieu_de_provenance = response.data.lieu_de_provenance || '';
    this.form.objet = response.data.objet || '';
    this.form.date_de_depart = response.data.date_de_depart ? new Date(response.data.date_de_depart).toISOString().substr(0, 10) : '';
    this.form.date_de_retour = response.data.date_de_retour ? new Date(response.data.date_de_retour).toISOString().substr(0, 10) : '';
    this.form.transport_en_commun = response.data.transport_en_commun || false;
   
        this.activeParticipants = response.data.participants;
        this.form.participants = response.data.participants.map(p => p.id);
        this.updateAvailableParticipants();
      } catch (error) {
        console.error('Failed to fetch demande_om:', error);
      }
    },
    async fetchParticipants() {
      try {
        const response = await api.getParticipants();
        this.participants = response.data;
        this.updateAvailableParticipants();
      } catch (error) {
        console.error('Failed to fetch participants:', error);
      }
    },
    updateAvailableParticipants() {
      const activeIds = this.form.participants;
      this.availableParticipants = this.participants.filter(p => !activeIds.includes(p.id));
    },
    filterParticipants() {
      const search = this.participantSearch.toLowerCase();
      const activeIds = this.form.participants;
      this.availableParticipants = this.participants.filter(participant => 
        participant.noms.toLowerCase().includes(search) && !activeIds.includes(participant.id)
      );
    },
    activateParticipants() {
      this.selectedInactiveParticipants.forEach(id => {
        const participant = this.participants.find(p => p.id === id);
        if (participant) {
          this.activeParticipants.push(participant);
          this.form.participants.push(id);
        }
      });
      this.selectedInactiveParticipants = [];
      this.updateAvailableParticipants();
    },
    deactivateParticipants() {
      this.selectedActiveParticipants.forEach(id => {
        this.activeParticipants = this.activeParticipants.filter(p => p.id !== id);
        this.form.participants = this.form.participants.filter(pId => pId !== id);
      });
      this.selectedActiveParticipants = [];
      this.updateAvailableParticipants();
    },
    handleFileUpload(event) {
      this.form.tdr= event.target.files[0];
    },
    async handleSubmit() {
      const id = this.$route.params.id;
      try {
        const formData = new FormData();
        Object.keys(this.form).forEach(key => {
          if (key === 'participants') {
            this.form[key].forEach(participant => {
              formData.append('participants[]', participant);
            });
          } else {
            formData.append(key, this.form[key]);
          }
        });
        /*if (this.form.selectedtdr) {
          console.log("TDR FILE",this.form.selectedtdr)
          formData.append('tdro', this.form.selectedtdr);
        }*/
         // Inspecting the FormData values
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }


        await api.updateDemandeOm(id, formData);
        this.$router.push({ name: 'ListeDemandeOms' });
      } catch (error) {
        console.error('Failed to update demande_om:', error);
      }
    },
    goBack() {
      this.$router.push({ name: 'ListeDemandeOms' });
    },
    formatDate(dateString) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric' };
        //return new Date(dateString).toLocaleDateString(undefined, options);
        return new Date(dateString).toISOString().substr(0, 10); 
      },
  }
};
</script>

<style>
/* Add any custom styles here */
</style>

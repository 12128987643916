<template>
    <div class="verify-email">
      <h2>Verify Email</h2>
      <p v-if="message">{{ message }}</p>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  
  export default {
    data() {
      return {
        message: '',
        error: ''
      };
    },
    async created() {
      const token = this.$route.query.token;
      if (token) {
        try {
          const response = await api.verifyEmail(token);
          this.message = response.data.message;
        } catch (error) {
          this.error = 'Email verification failed';
        }
      } else {
        this.error = 'Invalid token';
      }
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles spécifiques à ce composant ici */
  </style>